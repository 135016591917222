import {
	Button,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	notify,
	Row,
} from '@sobrus-com/sobrus-design-system-v2';
import { Fragment, useEffect, useRef, useState } from 'react';
import notificationsImg from '@/assets/glovo/notificationsImg.svg';
import NotificationSound from '@/assets/glovo/notification-sound.mp3';

import { useAddUpdate, useAppDispatch, useAppSelector } from '@/hooks';
import { setNotificationTotal } from '@/redux/features/notifiationsSlice';
import { notificationDataTypes } from '../notificationsTypes';
import { useNavigate } from 'react-router-dom';
import './glovoNotificationModal.scss';
interface glovoNotificationModalPropsTypes {
	// setModelOpen: Dispatch<SetStateAction<boolean>>
}

const GlovoNotificationModal = ({}: glovoNotificationModalPropsTypes) => {
	const dispatch = useAppDispatch();
	const audioPlayer = useRef<HTMLAudioElement | null>(null);
	const { Update, loading: formLoading } = useAddUpdate();
	const [modalOpen, setModelOpen] = useState<boolean>(false);
	const [noticationInModal, setnoticationInModal] =
		useState<notificationDataTypes | null>(null);
	/** Navigation   */
	const navigate = useNavigate();

	const { notifiations, notifiationsTotal } = useAppSelector(
		state => state.notifiations
	);

	const bell = new Audio(NotificationSound);
	useEffect(() => {
		// Define a function to play the audio in a loop
		// const playAudioLoop = () => {
		// 	bell.loop = true; // Set loop property to true
		// 	bell.play(); // Start playing the audio
		// };

		const playAudioLoop = async () => {
			try {
				await bell.play(); // Attempt to play audio
				bell.loop = true; // Set loop if play is successful
			} catch (err) {
				console.warn('Audio playback blocked:', err);
			}
		};

		if (
			notifiations.filter(el => el?.subject === 'Nouvelle commande GLOVO')
				?.length > 0
		) {
			if (
				noticationInModal?.id !==
				notifiations.filter(el => el?.subject === 'Nouvelle commande GLOVO')[0]
					?.id
			) {
				setnoticationInModal(
					notifiations.filter(
						el => el?.subject === 'Nouvelle commande GLOVO'
					)[0]
				);
			}

			// playAudioLoop(); // Call the function to play audio in a loop
			setModelOpen(true);
		} else {
			bell.muted = true;
			// bell.play();
		}

		if (
			notifiations.filter(el =>
				el?.subject.includes('Votre store GLOVO est désormais')
			)?.length > 0
		) {
			notify({
				type: 'success',
				msg: notifiations.filter(el =>
					el?.subject.includes('Votre store GLOVO est désormais')
				)[0]?.subject,
				delay: 4000,
			});
			Update({
				url: `notifications/${notifiations.filter(el => el?.subject.includes('Votre store GLOVO est désormais'))[0]?.id}/mark-as-seen`,
				cb: () => {
					dispatch(setNotificationTotal(notifiationsTotal - 1));
				},
			});
		}

		// Cleanup function
		return () => {
			bell.pause(); // Pause the audio when component unmounts
			bell.currentTime = 0; // Reset audio time
		};
	}, [notifiations]);

	const markAsSeenHandler = () => {
		Update({
			url: `notifications/${noticationInModal?.id}/mark-as-seen`,
			message: 'Votre modification a été faite avec succès',
			cb: () => {
				dispatch(setNotificationTotal(notifiationsTotal - 1));
				setModelOpen(false);
			},
			close: () => {
				setModelOpen(false);
			},
		});
	};

	if (modalOpen) {
		return (
			<Modal
				setOpen={() => markAsSeenHandler()}
				size={'lg'}
				className="glovoNotificationModal"
			>
				<ModalHeader setOpen={() => markAsSeenHandler()} />
				<ModalBody>
					<Container>
						<Row>
							<Col className="notifications_img_container">
								<img
									src={notificationsImg}
									alt="notifications modal"
									className="notifications_img"
								/>
								<p>Vous avez reçu une nouvelle commande</p>
								<audio ref={audioPlayer} src={NotificationSound} />
							</Col>
						</Row>
					</Container>
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						loading={formLoading}
						onClick={() => {
							Update({
								url: `notifications/${noticationInModal?.id}/mark-as-seen`,
								message: 'Votre modification a été faite avec succès',
								cb: () => {
									navigate(`/admin/orders/view/${noticationInModal?.orderId}`);
									dispatch(setNotificationTotal(notifiationsTotal - 1));
									setModelOpen(false);
								},
								close: () => {
									setModelOpen(false);
								},
							});
						}}
					>
						Consulter la commande
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
	return (
		<Fragment>
			<audio ref={audioPlayer} src={NotificationSound} />
		</Fragment>
	);
};

export default GlovoNotificationModal;
